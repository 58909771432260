import React, { Component } from 'react';
import { Link } from 'gatsby';
import { SiteContext } from '../Layout';

class WpMenu extends Component {
  render() {
    const { menuTitle } = this.props;
    return (
      <SiteContext.Consumer>
        {(value) => (
          <ul className="menu">
            {value.menus.map(
              ({ node }) =>
                node.title === menuTitle &&
                node.items.map(({ _key, classes, link }) => (
                  <li key={_key} className={classes}>
                    {link.newTab ? (
                      <a
                        href={link.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span
                          dangerouslySetInnerHTML={{
                            __html: link.copy,
                          }}
                        />
                      </a>
                    ) : (
                      <Link to={link.url}>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: link.copy,
                          }}
                        />
                      </Link>
                    )}
                  </li>
                ))
            )}
          </ul>
        )}
      </SiteContext.Consumer>
    );
  }
}

export default WpMenu;
