import React, { useState } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import Header from './Header';
import Footer from './Footer';
import { colors } from '../styles/utilities/settings';

export const SiteContext = React.createContext();

const Layout = ({ children }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  return (
    <StaticQuery
      query={LAYOUT_QUERY}
      render={({
        menus,
        site: { title, description, copyright, _rawMainLogo, _rawSiteImage },
      }) => (
        <SiteContext.Provider
          value={{
            siteTitle: title,
            siteDescription: description,
            siteImage: _rawSiteImage,
            menuOpen,
            toggleMenu: () => {
              setMenuOpen(!menuOpen);
            },

            toggleModal: () => {
              setModalOpen(!modalOpen);
            },

            mainLogo: _rawMainLogo,
            menus: menus.edges,
            copyright,
          }}
        >
          <a
            style={{
              position: 'fixed',
              zIndex: '-9999',
              padding: '20px',
              background: colors.black,
              color: colors.white,
              border: `3px solid ${colors.white}`,
            }}
            className="skip-link"
            href="#bodyContent"
          >
            Skip to body.
          </a>
          <main>
            <Header />
            <div id="bodyContent" className="body-content">
              {children}
            </div>
            <Footer />
          </main>
        </SiteContext.Provider>
      )}
    />
  );
};

export default Layout;

const LAYOUT_QUERY = graphql`
  {
    site: sanitySiteSettings {
      title
      description
      copyright
      _rawSocialList(resolveReferences: { maxDepth: 10 })
      _rawMainLogo(resolveReferences: { maxDepth: 10 })
      _rawSiteImage(resolveReferences: { maxDepth: 10 })
    }
    menus: allSanityMenu {
      edges {
        node {
          title
          items {
            _key
            classes
            link {
              url
              newTab
              copy
            }
            subItems {
              _key
              copy
              newTab
              url
            }
          }
        }
      }
    }
  }
`;
