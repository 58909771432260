import React from 'react';
import styled from 'styled-components';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import { colors, font, misc } from '../../styles/utilities/settings';
import { below } from '../../styles/utilities/mediaQueries';
import { Input } from '../../styles/reusableObjects/Inputs';

const NewsletterSignup = ({ status, message, onValidated }) => {
  let email;
  const submit = () =>
    email &&
    email.value.indexOf('@') > -1 &&
    onValidated({
      EMAIL: email.value,
    });

  return (
    <div className="form">
      {status === 'sending' && (
        <div className="status" style={{ color: colors.blue }}>
          sending...
        </div>
      )}
      {status === 'error' && (
        <div
          className="status"
          style={{ color: colors.orange }}
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
      {status === 'success' && (
        <div
          className="status"
          style={{ color: colors.blue }}
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
      <div className="inner">
        <Input
          ref={(node) => (email = node)}
          type="email"
          placeholder="Email Address"
        />
        <button type="submit" onClick={submit}>
          OK
        </button>
      </div>
    </div>
  );
};

const NewsLetter = ({ children }) => (
  <SNewsLetter>
    {children && <div>{children}</div>}

    <MailchimpSubscribe
      url="https://dougthepugfoundation.us19.list-manage.com/subscribe/post?u=af2c9122696502a63bef338b6&amp;id=0ee2920770"
      render={({ subscribe, status, message }) => (
        <NewsletterSignup
          status={status}
          message={message}
          onValidated={(formData) => subscribe(formData)}
        />
      )}
    />
  </SNewsLetter>
);

export default NewsLetter;

export const SNewsLetter = styled.div`
  margin-top: 10px;

  ${below.ipadPort`
      margin-top: 30px;
  `}

  > div {
    margin-bottom: 20px;
  }

  .msg-alert {
    margin-top: 20px;
    margin-bottom: 0;
  }

  .form {
    position: relative;
    display: flex;
    width: 100%;
    flex-wrap: wrap;

    ${below.ipadMid`
        margin-bottom: 11px;
        `}

    .inner {
      border: 1px solid rgb(0 0 0 / 5%);
      display: flex;
      width: 100%;
      max-width: 250px;
    }

    .status {
      margin-bottom: 10px;
      font-weight: 600;
    }
  }

  .mc-field-group {
    width: 80%;

    input {
      width: 100%;
    }
  }

  input {
    padding: 12px 10px 8px;
    font-size: 14px;
    outline: 0;
    border: 0;
    width: 80%;
    height: 40px;

    &::placeholder {
      color: ${colors.black};
    }
  }

  .clear {
    input[type='submit'] {
      width: 100%;
      height: 100%;
      padding: 0 13.5px;
    }
  }

  input[type='submit'],
  button {
    width: 20%;
    padding-top: 3px;
    border: 0;
    cursor: pointer;
    font-size: 10px;
    font-family: ${font.primary};
    text-transform: uppercase;
    background: ${colors.blue};
    color: ${colors.white};
    font-weight: 800;
    height: 40px;
    transition-duration: ${misc.animSpeed};

    &:hover,
    &:focus {
      background-color: ${colors.yellow};
    }
  }
`;
