import React, { useContext } from 'react';
import styled from 'styled-components';
import Wrapper from '../styles/utilities/Wrapper';
import Menu from './reusableObjects/Menu';
import SocialList, { SSocialList } from './reusableObjects/SocialList';

import { colors } from '../styles/utilities/settings';
import { SiteContext } from './Layout';
import { above, below } from '../styles/utilities/mediaQueries';
import NewsLetter, { SNewsLetter } from './forms/NewsLetter';

const Footer = () => {
  const value = useContext(SiteContext);

  const date = new Date();
  const currentYear = date.getFullYear();

  return (
    <SFooter>
      <Wrapper>
        <div className="copy">
          <img className="paw" src="/paw.png" alt={value.siteTitle} />
          <p
            dangerouslySetInnerHTML={{
              __html: value.copyright.replace('[year]', currentYear),
            }}
          />
          <p style={{ marginTop: '10px', lineHeight: '1.5' }}>
            The Doug The Pug Foundation is a Tennessee non-profit corporation
            with tax exemption under Internal Revenue Code Section 501(c)(3).
            The Foundation does not give tax advice and you should consult a tax
            professional for any tax consequences or responsibilities as a
            result on charitable donations to the Foundation.
          </p>
        </div>
        <nav>
          <Menu menuTitle="Footer Menu" />
          <SocialList />
          <NewsLetter>
            <p>Subscribe to our newsletter:</p>
          </NewsLetter>
        </nav>
      </Wrapper>
    </SFooter>
  );
};

export default Footer;

const SFooter = styled.footer`
  background-color: ${colors.black};
  padding: 50px 0;
  color: ${colors.white};
  font-size: 14px;

  ${Wrapper} {
    max-width: 1200px;
    padding: 0 30px;

    ${above.ipadPort`
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    `}
  }

  .paw {
    max-width: 40px;
    margin-bottom: 15px;
  }

  .copy {
    font-size: 10px;
    padding: 10px 0;

    ${above.ipadPort`
      width: 40%;
    `}

    ${below.ipadPort`
      margin-bottom: 30px;
    `}
  }

  ${SSocialList} {
    flex-direction: column;

    ${below.ipadPort`
      position: relative;
      left: 30px;
      width: 50%;
    `}

    svg {
      width: 20px;
      position: absolute;
      right: calc(100% + 10px);
      top: 50%;
      transform: translateY(-50%);
    }

    li {
      a {
        padding: 10px 0;
        position: relative;

        &:hover {
          color: ${colors.yellow};

          path {
            fill: ${colors.yellow} !important;
          }
        }
      }
    }
  }

  nav {
    max-width: 620px;
    display: flex;

    ${above.ipadPort`
      width: 60%;
      justify-content: space-between;
    `}

    ${below.ipadPort`
      flex-wrap: wrap;
    `}

    .menu {
      ${below.ipadPort`
      width: 50%;
    `}
      a {
        padding: 10px 0;
      }
    }
  }

  a {
    display: inline-block;
    color: ${colors.white};

    &:hover {
      color: ${colors.yellow};

      svg {
        fill: ${colors.blue};
      }
    }
  }

  ${SNewsLetter} {
    width: 100%;
    max-width: 250px;
  }
`;
