import styled from 'styled-components';
import { wrap } from './layout';
import { breakpoints } from './settings';

const Wrapper = styled.div`
  ${wrap}
  ${(props) => props.width && `max-width: ${props.width}px;`}
  ${(props) => props.narrow && `max-width: ${breakpoints.ipadPort}px;`}
  ${(props) => props.narrower && `max-width: ${breakpoints.ipadPort - 200}px;`}
  ${(props) => props.full && 'max-width: none; padding: 0;'}
  ${(props) => props.wide && 'max-width: 1100px;'}
`;

export default Wrapper;
