import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import IcomoonReact from 'icomoon-react';
import iconSet from '../fonts/selection.json';
import { SiteContext } from './Layout';
import Wrapper from '../styles/utilities/Wrapper';
import {
  colors,
  misc,
  spacing,
  font,
  breakpoints,
} from '../styles/utilities/settings';
import Menu from './reusableObjects/Menu';
import imageUrlFor, { buildImageObj } from '../js/imageUrlFor';
import { button } from '../styles/utilities/elements';

const Header = () => {
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    let lastScrollPosition = 0;
    let ticking = false;

    function scrollPos(scroll_pos) {
      setScrollPosition(scroll_pos);
    }

    global.window.addEventListener('scroll', () => {
      lastScrollPosition = global.window.scrollY;

      if (!ticking) {
        global.window.requestAnimationFrame(() => {
          scrollPos(lastScrollPosition);
          ticking = false;
        });

        ticking = true;
      }
    });
  }, []);
  return (
    <SiteContext.Consumer>
      {(value) => (
        <SHeader
          className={scrollPosition > 100 ? 'scrolling' : null}
          menuOpen={value.menuOpen}
          alert={value.alertBar}
        >
          <Wrapper>
            <InnerHeader menuOpen={value.menuOpen}>
              <Link to="/" className="logo">
                <img
                  src={imageUrlFor(buildImageObj(value.mainLogo))
                    .width(250)
                    .quality(100)}
                  alt={value.siteTitle}
                />
              </Link>
              <MenuToggle
                href={null}
                onClick={() => {
                  value.toggleMenu();
                }}
              >
                <IcomoonReact
                  iconSet={iconSet}
                  color={colors.black}
                  size={30}
                  icon={value.menuOpen ? 'cross' : 'menu'}
                />
              </MenuToggle>
              <MainMenu>
                <Menu menuTitle="Main Menu" />
                <TopBarMenu>
                  <Wrapper>
                    <Menu menuTitle="Top Menu" />
                  </Wrapper>
                </TopBarMenu>
              </MainMenu>
            </InnerHeader>
          </Wrapper>
        </SHeader>
      )}
    </SiteContext.Consumer>
  );
};

export default Header;

// Menu Toggle Styles
export const MenuToggle = styled.a`
  cursor: pointer;
  position: relative;
  z-index: 3;

  @media screen and (min-width: ${breakpoints.ipadPort}px) {
    display: none;
  }
`;

// Top Bar Menu Styles
export const TopBarMenu = styled.div`
  padding: 5px 0;

  li {
    a {
      text-decoration: underline;
      font-size: 13px;
    }
  }

  @media screen and (min-width: ${breakpoints.ipadPort}px) {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
  }
`;

// Main Menu Styles
export const MainMenu = styled.nav`
  font-family: ${font.primary};

  @media (max-width: 1139px) and (min-width: ${breakpoints.ipadPort}px) {
    width: 100%;
    margin-top: 10px;
  }

  @media screen and (max-width: ${breakpoints.ipadPort - 1}px) {
    position: fixed;
    top: 0;
    background-color: ${colors.white};
    width: 80vw;
    height: 100vh;
    transition-duration: ${misc.animSpeed};
    padding-top: ${spacing.headerMobile};
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  ul {
    @media screen and (min-width: ${breakpoints.ipadPort}px) {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
    }

    @media (max-width: 1139px) and (min-width: ${breakpoints.ipadPort}px) {
      justify-content: space-between;
    }

    > li {
      @media screen and (min-width: ${breakpoints.ipadPort}px) {
        text-align: center;
      }

      @media screen and (max-width: ${breakpoints.ipadPort - 1}px) {
        text-align: right;

        + li {
          border-top: 1px solid ${colors.gray};
        }
      }

      @media (max-width: 1139px) and (min-width: ${breakpoints.ipadPort}px) {
        + li {
          margin: 0 !important;
        }
      }

      &.hasSub {
        position: relative;

        > a:first-of-type {
          @media screen and (max-width: ${breakpoints.ipadPort - 1}px) {
            padding-right: 70px;
          }
        }

        &.open {
          ul {
            @media screen and (max-width: ${breakpoints.ipadPort - 1}px) {
              display: block;
            }
          }
        }

        &:hover,
        &.open {
          ul {
            @media screen and (min-width: ${breakpoints.ipadPort}px) {
              position: absolute;
              top: 100%;
              display: block;
              width: 200px;
            }
          }
        }
      }

      + li {
        @media screen and (min-width: ${breakpoints.ipadPort}px) {
          margin-left: 20px;
        }
      }

      ul {
        display: none;
        @media screen and (min-width: ${breakpoints.ipadPort}px) {
          background-color: ${colors.white};
          box-shadow: 0 10px 10px -15px ${colors.black};
        }

        li {
          text-align: left;
          @media screen and (max-width: ${breakpoints.ipadPort - 1}px) {
            text-align: right;
            border-top: 1px solid ${colors.gray};
          }
        }
      }

      > a {
        display: block;
        color: ${colors.black};
        font-weight: bold;
        font-size: 13px;
        text-transform: uppercase;
        letter-spacing: 0.6px;
        @media screen and (min-width: ${breakpoints.ipadPort}px) {
          padding: 10px;
        }

        @media screen and (max-width: ${breakpoints.ipadPort - 1}px) {
          padding: 15px 20px;
        }
      }
    }
  }

  ${TopBarMenu} {
    ul {
      display: flex;
      justify-content: flex-end;
    }

    li {
      margin-left: 20px;
      border-top: 0;
    }
  }

  .subToggle {
    @media screen and (min-width: ${breakpoints.ipadPort}px) {
      display: none;
    }

    @media screen and (max-width: ${breakpoints.ipadPort - 1}px) {
      border-left: 1px solid ${colors.black};
      position: absolute;
      right: 0;
      top: 0;
      height: 46px;
      width: 46px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
    }
  }
`;

// Inner Container Styles
const InnerHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 1139px) and (min-width: ${breakpoints.ipadPort}px) {
    flex-wrap: wrap;
  }

  @media screen and (max-width: ${breakpoints.ipadPort - 1}px) {
    ${MainMenu} {
      right: ${({ menuOpen }) => (menuOpen ? '0' : '-80vw')};
    }
  }

  .logo {
    display: block;
    position: relative;
    z-index: 3;
    transition-duration: ${misc.animSpeed};
    width: 200px;

    @media screen and (max-width: ${breakpoints.ipadPort - 1}px) {
      left: ${({ menuOpen }) => (menuOpen ? '20vw' : '0')};
      width: 150px;
    }

    img {
      display: block;

      ~ p {
        margin-top: 5px;
        font-size: 12px;

        @media screen and (max-width: ${breakpoints.ipadPort - 1}px) {
          display: none;
        }
      }
    }
  }
`;

// Header Styles
const SHeader = styled.header`
  padding: 20px 0;
  box-shadow: 0 0 0 0 ${colors.black};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  background-color: ${colors.white};
  transition-duration: ${misc.animSpeed};

  &.scrolling {
    box-shadow: 0 0 20px -13px ${colors.black};
  }

  &:before {
    @media screen and (max-width: ${breakpoints.ipadPort - 1}px) {
      content: '';
      display: block;
      background-color: ${colors.black};
      position: fixed;
      height: 100vh;
      width: 100vh;
      z-index: -1;
      transition-duration: ${misc.animSpeed};
      opacity: ${({ menuOpen }) => (menuOpen ? '0.7' : '0')};
      top: 0;
      left: 0;
      pointer-events: ${({ menuOpen }) => (menuOpen ? 'all' : 'none')};
    }
  }

  li {
    &.button {
      a {
        ${button};
        color: ${colors.white};

        @media screen and (min-width: ${breakpoints.ipadPort}px) {
          margin-left: 10px;
        }

        @media screen and (max-width: ${breakpoints.ipadPort - 1}px) {
          display: block;
          text-align: center;
          margin: 20px auto 0;
          max-width: 90%;
        }
      }
    }
  }

  a {
    color: ${colors.blue};
    @media screen and (min-width: ${breakpoints.ipadPort}px) {
      transition-duration: ${misc.animSpeed};
    }

    &:hover {
      @media screen and (min-width: ${breakpoints.ipadPort}px) {
        color: ${colors.blue};
      }
    }
  }

  ${Wrapper} {
    max-width: none;
  }

  ${MainMenu} {
    @media screen and (max-width: ${breakpoints.ipadPort - 1}px) {
      ${({ alert }) =>
        alert ? 'height: calc(100vh - 26px); margin-top: 26px;' : ''}
    }
  }
`;
