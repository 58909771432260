import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import IcomoonReact from 'icomoon-react/build/src/IcomoonReact';
import styled from 'styled-components';
import iconSet from '../../fonts/selection.json';
import { colors } from '../../styles/utilities/settings';

// TODO: Fix social icons

const SocialList = () => (
  <StaticQuery
    query={SOCIAL_LIST}
    render={({ settings: { _rawSocialList } }) => (
      <SSocialList>
        {_rawSocialList.map(({ icon, link, _key }) => (
          <li key={_key}>
            <a href={link} target="_blank" rel="noopener noreferrer">
              <IcomoonReact
                iconSet={iconSet}
                color={colors.white}
                size={20}
                icon={icon}
              />
              {icon}
            </a>
          </li>
        ))}
      </SSocialList>
    )}
  />
);

export default SocialList;

const SOCIAL_LIST = graphql`
  {
    settings: sanitySiteSettings {
      _rawSocialList(resolveReferences: { maxDepth: 10 })
    }
  }
`;

export const SSocialList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
`;
